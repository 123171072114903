<template>
    <!--注意： 表单中需要右对齐的才加  class="form-title"-->
    <!--注意： 表单中需要右对齐的才加  class="form-title"-->
    <!--注意： 表单中需要右对齐的才加  class="form-title"-->
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return">
                    <el-button type="text" size="small" icon="el-icon-arrow-left" @click="back">返回</el-button>
                </p>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="add">新增</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="save">保存</el-button>
                </div>
            </div>
        </div>
        <div>
            <el-form ref="basicForm" :model="dataSource" :rules="basicValidate">
                <el-tabs type="border-card" v-model.trim="activeName">
                    <el-tab-pane label="" name="tabbasic">
                        <el-collapse v-model.trim="collapseItem">
                            <el-collapse-item title="基本信息" name="basicinfo">
                                <el-form-item>
                                    <el-col :span="3" class="form-title">停售单据编码：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.GspRecordNo"  readonly disabled></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="1">
                                        <GridDialog
                                            dataTitle="药品停售"
                                            dataUrl='omsapi/gspdrugstopsale/getauditlist'
                                            @syncFieldEvent="syncGspStopSale"
                                        ></GridDialog>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>货主编号：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="CustomerCode">
                                            <el-input v-model.trim="dataSource.CustomerCode"
                                                readonly disabled
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">货主名称：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.CustomerName"
                                                readonly disabled
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>客商编号：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="VendorCode">
                                            <el-input v-model.trim="dataSource.VendorCode"
                                                readonly disabled
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">客商名称：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.VendorName"
                                                readonly disabled
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title"><span style="color:red;">*</span>商品编码：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="ProductNo">
                                            <el-form-item prop="ProductNo">
                                            <el-input v-model.trim="dataSource.ProductNo"
                                                readonly disabled
                                            ></el-input>
                                        </el-form-item>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">商品中文名称：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductName" readonly disabled></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">规格：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductSpec" readonly disabled></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">生成厂家：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.ProductCompany" readonly disabled></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">批准文号：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="ProductApproveNum">
                                            <el-input v-model.trim="dataSource.ProductApproveNum" readonly disabled></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">包装单位：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="ProductUnit">
                                            <el-input v-model.trim="dataSource.ProductUnit" readonly disabled></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">有效期至：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="ProductExpiryDate">
                                            <el-date-picker v-model.trim="dataSource.ProductExpiryDate" placeholder="选择日期"
                                                type="datetime" value-format="yyyy-MM-dd HH:mm:ss" readonly disabled>
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">生成日期：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-date-picker v-model.trim="dataSource.ProductProduceDate" placeholder="选择日期"
                                                type="datetime" value-format="yyyy-MM-dd HH:mm:ss" readonly disabled>
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">停售方式：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <DictionarySelect v-model="dataSource.StopType" datType='Int' dicTypeCode="ProductStopTypeCode" readonly disabled>
                                            </DictionarySelect>
                                        </el-form-item>
                                    </el-col>
                                    <template v-if="stopType==200">
                                        <el-col :span="3" class="form-title">批次：</el-col>
                                        <el-col :span="9" >
                                            <el-form-item>
                                                <el-input v-model.trim="dataSource.ProductBatch" readonly disabled></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </template>
                                    <template v-else-if="stopType==300">
                                        <el-col :span="3" class="form-title">批号：</el-col>
                                        <el-col :span="9">
                                            <el-form-item>
                                                <el-input v-model.trim="dataSource.ProductBatchCode" readonly disabled></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </template>
                                    
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">涉及数量：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-input v-model.trim="dataSource.StopNum" readonly disabled></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">后续处理方式：</el-col>
                                    <el-col :span="9" prop="StopHandleType">
                                        <el-form-item>
                                            <DictionarySelect v-model="dataSource.StopHandleType" datType='Int' dicTypeCode="ProductStopHandleTypeCode" readonly disabled>
                                            </DictionarySelect>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">停售原因：</el-col>
                                    <el-col :span="9">
                                        <el-form-item prop="StopReasonType">
                                            <DictionarySelect v-model="dataSource.StopReasonType" datType='Int' dicTypeCode="ProductStopReasonTypeCode" readonly disabled>
                                            </DictionarySelect>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">备注：</el-col>
                                    <el-col :span="21">
                                        <el-form-item>
                                            <el-input type="textarea" 
                                                v-model.trim="dataSource.StopRemark"
                                                rows="4"
                                                :maxlength="200" readonly disabled>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                            </el-collapse-item>
                            <el-collapse-item title="停售审核" name="basicinfo1">
                                <el-form-item>
                                    <el-col :span="3" class="form-title">审核时间：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <el-date-picker v-model.trim="dataSource.AuditTime" placeholder="选择日期"
                                                type="datetime" value-format="yyyy-MM-dd HH:mm:ss" readonly disabled>
                                            </el-date-picker>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="3" class="form-title">审核状态：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <DictionarySelect v-model="dataSource.AuditStatus" datType='Int' dicTypeCode="GspStopSaleAuditStatusCode" readonly disabled>
                                            </DictionarySelect>
                                        </el-form-item>
                                    </el-col>
                                    
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">制单人：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <LookPopup v-model="dataSource.CreatorName"
                                                dataTitle="用户选择"
                                                dataUrl='omsapi/sysuser/getlist'
                                                syncField='UserName'
                                            ></LookPopup>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">审核意见：</el-col>
                                    <el-col :span="21">
                                        <el-form-item>
                                            <el-input type="textarea" 
                                                v-model.trim="dataSource.AuditContent"
                                                rows="4"
                                                :maxlength="200" readonly disabled>
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                            </el-collapse-item>
                            <el-collapse-item title="解除停售" name="basicinfo2">
                                <el-form-item>
                                    <el-col :span="3" class="form-title">解除状态：</el-col>
                                    <el-col :span="9">
                                        <el-form-item>
                                            <DictionarySelect v-model="dataSource.CancelAuditStatus" datType='Int' dicTypeCode="GspStopSaleCancelStatusCode">
                                            </DictionarySelect>
                                        </el-form-item>
                                    </el-col>
                                    
                                </el-form-item>
                                <el-form-item>
                                    <el-col :span="3" class="form-title">解除停售原因：</el-col>
                                    <el-col :span="21">
                                        <el-form-item>
                                            <el-input type="textarea" 
                                                v-model.trim="dataSource.CancelAuditContent"
                                                rows="4"
                                                :maxlength="200">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-form-item>
                            </el-collapse-item>
                        </el-collapse>
                    </el-tab-pane>
                </el-tabs>
            </el-form> 
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                collapseItem: ['basicinfo','basicinfo1','basicinfo2','basicinfo3'],
                basicValidate: {
                    CustomerCode:[{ required: true, message: '货主不能为空', trigger: 'blur' }],
                    VendorNo:[{ required: true, message: '客商不能为空', trigger: 'blur' }],
                    ProductNo:[{ required: true, message: '商品不能为空', trigger: 'blur' }],
                    ProductBatchCode:[{ required: true, message: '批号不能为空', trigger: 'blur' }],
                    ProductNum:[{ required: true, message: '数量不能为空', trigger: 'blur' }],
                    ComplaintContent:[{ required: true, message: '质量问题不能为空', trigger: 'blur' }],
                },
                activeName:"tabbasic",
                dataSource:{
                },
                processStatusList:[],
                stopType:null,
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                isAdd:false
            },
        },
        mounted: function () {
        },
        computed: {
        },
        watch: {
            'config.dataSourceKey':{
                handler(curVal, oldVal) {
                    if(curVal&&curVal>0){
                        if(curVal!=oldVal) 
                            this.initial(curVal); //初始化
                    }else{
                        this.add(); //新增初始化
                    }
                },
                deep: true
            }
        },
        methods: {
            //重置表单
            resetForm: function () {
                this.Utils.clearForm(this.dataSource);
            },
            back: function () {
                this.config.isDetailDisplay = false;
            },
            add: function () {
                this.initial();
                this.config.isAdd = true;
            },
            initial(id){
                var _this = this;
                _this.$ajax.send("omsapi/gspdrugstopsale/get", "get", { id: id }, (data) => {
                    _this.dataSource=data.Result;
                    _this.$refs["basicForm"].clearValidate();
                });
            },
            save: function () {
                var _this = this;
                _this.$refs["basicForm"].validate((valid) => {
                    if (valid) {
                        _this.$ajax.send(
                            'omsapi/gspdrugstopsale/cancelaudit',
                            'post',
                            _this.dataSource,
                            (data) => {
                                _this.Event.$emit('reloadPageList', data.Result)
                                _this.Utils.messageBox('保存成功.', 'success')
                            }
                        )
                    } else {
                        return false
                    }
                });
            },
            syncGspStopSale:function(row){
                if(row){
                    this.dataSource = row;
                }
            }
        },
        components: {
        }
    }
</script>